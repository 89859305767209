var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"channel-statistics"},[_c('page-title'),_c('mobile-landscape-trigger-layout'),_c('div',{staticClass:"date-pickers w-full flex flex-col lg:flex-row md:justify-end md:gap-2"},[_c('date-picker-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'from',
          'clearable': false,
          'maxDate': _vm.moment(_vm.to).toDate()
        }
      }}}),_c('date-picker-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'to',
          'clearable': false,
          'minDate': _vm.moment(_vm.from).toDate()
        },
      }}}),_c('div',{staticClass:"flex justify-end md:items-end py-1"},[_c('a-button',{attrs:{"type":"primary","icon":"search","disabled":!_vm.statisticsLoaded},on:{"click":_vm.getStatistics}})],1)],1),(_vm.statisticsLoaded)?_c('channel-stat',{staticClass:"mt-5",attrs:{"from":_vm.from,"to":_vm.to,"statistics":_vm.statistics}}):_c('a-spin',{staticClass:"w-full"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }


























































import ChannelStat from '@/components/ChannelStat.vue'
import { ChannelService } from '@/includes/services/ChannelService'
import { InputSetups } from '@/mixins/input-setups'
import MobileLandscapeTriggerLayout from '@/components/MobileLandscapeTriggerLayout.vue'
import { errorNotification } from '@/includes/services/NotificationService'
import { TargetFullStat } from '@/includes/types/TargetStat.types'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import moment from 'moment'

@Component({
  components: {
    PageTitle,
    ChannelStat,
    MobileLandscapeTriggerLayout
  },
  methods: {
    moment
  },
})
export default class ChannelStatistics extends Mixins<InputSetups, UseFields>(InputSetups, UseFields) {
  from = moment().add('-31', 'day').format('YYYY-MM-DD')

  to = moment().add('1', 'day').format('YYYY-MM-DD')

  statisticsLoaded = false

  statistics: TargetFullStat | null = null

  getStatistics() {
    this.loadStatistic()
  }

  loadStatistic() {
    this.statisticsLoaded = false

    ChannelService.getChannelStat('tg', {
      board_key: this.$store.getters.activeBoard!.board,
      target_id: this.$store.state.channelsState.activeChannel.id,
      interval: {
        interval_type: 'Full',
        from: this.from,
        to: this.to
      }
    })
      .then(({ data }) => {
        this.statistics = data
      })
      .catch(errorNotification)
      .finally(() => {
        this.statisticsLoaded = true
      })
  }

  created() {
    this.loadStatistic()
  }
}

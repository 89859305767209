















import RecordItem from '@/components/StatisticsRecordsCards/RecordItem/RecordItem.vue'
import { RecordCard } from '@/components/StatisticsRecordsCards/RecordCard.types'

import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {
    RecordItem,
  }
})
export default class StatisticsRecordsCards extends Vue {

  @Prop() statisticsItems !: Array<RecordCard>
}

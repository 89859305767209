























































































































import StatisticsRecordsCards from '@/components/StatisticsRecordsCards/StatisticsRecordsCards.vue'
import ChartExportTableMixin from "@/mixins/statistics/ChartExportTableMixin";
import { TargetFullStat } from '@/includes/types/TargetStat.types'

import TableExportButton from 'piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue'
import LineChart from 'piramis-base-components/src/components/Charts/LineChart/lineChart.vue'
import { seriesByMetricInList } from 'piramis-base-components/src/shared/utils/seriesByMetricInList'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'
import { ApexChartLocales, ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue';

import Component from 'vue-class-component'
import VueApexCharts from 'vue-apexcharts'
import { Mixins, Prop } from 'vue-property-decorator'
import moment from 'moment'
import { ApexOptions } from 'apexcharts'

@Component({
  components: {
    VueApexCharts,
    StatisticsRecordsCards,
    TableExportButton,
    LineChart,
    ConfigField
  },
  methods: {
    moment
  }
})
export default class ChannelStat extends Mixins(ChartExportTableMixin) {
  @Prop({ type: Object }) statistics!: TargetFullStat | null

  @Prop() from!: string

  @Prop() to!: string

  usersStatSeries: ApexChartSeries | null = null

  postsStatSeries: ApexChartSeries | null = null

  postsViewsStatSeries: ApexChartSeries | null = null

  postsMetaStatSeries: ApexChartSeries | null = null

  subscribersOptions: ApexOptions = {
    ...this.baseOptions(),
    colors: [ '#69b744', '#fa9ea2' ],
    labels: this.fromToTicks('YYYY-MM-DDT00:00').timeTicks.map(t => moment(t).format('YYYY-MM-DD')),
  }

  postsOptions: ApexOptions = {
    ...this.baseOptions(),
    colors: [ '#5cadff', '#69b744' ],
    labels: this.fromToTicks('YYYY-MM-DD').timeTicks,
  }

  postsMetaOptions: ApexOptions = {
    ...this.baseOptions(),
    colors: [ '#69b744', '#fb7649' ],
    labels: this.fromToTicks('YYYY-MM-DD').timeTicks,
  }

  get recordCards() {
    if (this.statistics) {
      return [
        {
          title: this.$t('native_all_enter'),
          value: Number(this.statistics.native_all_enter).toFixed(2),
          color: 'var(--a-success)',
          icon: 'icon-user-plus',
        },
        {
          title: this.$t('native_all_leave'),
          value: Number(this.statistics.native_all_leave).toFixed(2),
          color: 'var(--a-danger)',
          icon: 'icon-user-minus',
        } ]
    }

    return []
  }

  fromToTicks(format: string) {
    return fromToTicks(moment(this.from).format(format), moment(this.to).format(format), { format })
  }

  baseOptions(): ApexOptions {
    return {
      chart: {
        toolbar: {
          show: true,
          tools: {
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            download: false,
          }
        },
        height: 350,
        zoom: {
          enabled: true
        },
        animations: {
          enabled: false,
        },
        defaultLocale: this.$i18n.locale,
        locales: ApexChartLocales,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2.5,
      },
      labels: [],
      xaxis: {
        type: "datetime",
        labels: {
          show: true,
        },
      },
      colors: [ ],
      yaxis: {
        labels: {
          formatter: (value) => parseInt(value.toString()).toString(),
        },
      },
      legend: {
        horizontalAlign: 'center',
      },
    }
  }

  usersStat() {
    if (this.statistics) {
      seriesByMetricInList(this.$i18n, this.statistics.subscribers, [ 'all_enter', 'all_leave' ], this.fromToTicks('YYYY-MM-DDT00:00'), 'date')
        .calcSeries()
        .then(s => this.usersStatSeries = s)
    }
  }

  postsStat() {
    if (this.statistics) {
      seriesByMetricInList(this.$i18n, this.statistics.posts, [ 'posts', 'ad_posts' ], this.fromToTicks('YYYY-MM-DD'), 'date')
        .calcSeries()
        .then(s => this.postsStatSeries = s)
    }
  }

  postsViewsStat() {
    if (this.statistics) {
      seriesByMetricInList(this.$i18n, this.statistics.posts, [ 'views12' ], this.fromToTicks('YYYY-MM-DD'), 'date')
        .calcSeries()
        .then(s => this.postsViewsStatSeries = s)
    }
  }

  postsMetaStat() {
    if (this.statistics) {
      seriesByMetricInList(this.$i18n, this.statistics.posts, [ 'fwd12', 'comments' ], this.fromToTicks('YYYY-MM-DD'), 'date')
        .calcSeries()
        .then(s => this.postsMetaStatSeries = s)
    }
  }

  created() {
    this.usersStat()
    this.postsStat()
    this.postsViewsStat()
    this.postsMetaStat()
  }
}
